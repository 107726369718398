import React, { useEffect, useState, useRef } from 'react';
import poseidon from '../img/poseidon.png';
import axios from 'axios';
import { Button, Container, Typography, Box, InputAdornment, Table, ToggleButtonGroup, Checkbox, FormControlLabel, CircularProgress, TableBody, ToggleButton, Dialog, Drawer, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, TextField, Grid, AppBar, Toolbar, IconButton, Link, Badge, Alert, useTheme, Tooltip, Popover, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InvoiceIcon from '@mui/icons-material/Receipt'; // Import the invoice icon
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom'; ClearIcon
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import translatedata from '../translate.json';
import Tutorial from '../component/Tutorial';
import Cookies from 'js-cookie';

import Qusetion from '../media/question.gif';
import LiveGif from '../media/red-circle.gif';
import Loading from '../component/loading';
const Poseidon = ({ recheck, balance, blockbalance, setBalance, messages, setChatOpen, setBlockBalance, newbalance, blockedbalance, poseidonfee, selectedLanguage, isLoggedIn, setLoginOpen }) => {
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [data, setData] = useState([]);
    const [fdata, setFData] = useState([]);
    const [totalPrice, setTotalPrice] = useState();
    const [nowbalance, setNowBalance] = useState(balance);
    const [nowblockbalance, setNowBlockBalance] = useState(blockbalance);
    const [winvalue, setWinvalue] = useState();
    const [coupon, setCoupon] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [stat, setStat] = useState(false);
    const [searchterm, setSearchterm] = useState();
    const [opentool, setOpenTool] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataLive, setFilteredDataLive] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const popoverRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile2 = useMediaQuery(theme.breakpoints.down('lg'));
    const [tutorialid, setTutorialid] = useState(13);
    const [tutorialopen, setTutorialOpen] = useState(false);
    const [livedata, setLiveData] = useState(null);
    const [selectedtype, setSelectedtype] = useState("3");
    const [livecount, setLivecount] = useState(10);
    const [precount, setPrecount] = useState(10);
    const [senloading, setSendLoading] = useState(false);
    const [loadinglive, setLoadinglive] = useState(true);
    const [buttonloadinglive, setButtonLoadingLive] = useState(false);
    const [loadingpre, setLoadingpre] = useState(true);
    const [buttonloadingpre, setButtonLoadingPre] = useState(false);
    const [oddtype, setOddType] = useState("LAY");
    const [oddtype2, setOddType2] = useState("availableToLay");
    const [changedodd, setChangedOdd] = useState(false);
    const [searchval, setSearchValue] = useState();
    useEffect(() => {
        const changeodd = cookies.changeodd;
        if (changeodd == true) {
            setChangedOdd(true)
        }
    }, [])
    const changeoddconf = (conf) => {
        setCookie('changeodd', conf);
        setChangedOdd(conf)
    }
    const toggleDrawer = (open) => {

        setDrawerOpen(open);
    };

    useEffect(() => {
        if (!searchterm) {

            const fetchData = async () => {
                try {

                    const accessToken = cookies.serviceToken;
                    const config = {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    };
                    const response = await axios.get('https://api.youbetshop.com/api/feed/poseidon-feed-live?count=' + livecount, config);
                    setLiveData(response.data.data.filter((item) => item.state?.elapsedRegularTime <= 85));
                    setLoadinglive(false)
                    setButtonLoadingLive(false)
                } catch (error) {
                    console.error('Veri alınırken hata oluştu:', error);
                }
            };
            if (livecount != 0) {
                fetchData();
                const intervalId = setInterval(fetchData, 5000);

                // Component unmount edildiğinde interval'i temizle
                return () => clearInterval(intervalId);
            }

        }

    }, [searchterm, livecount]);
    const navigate = useNavigate();
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setNowBalance(newbalance);
        setNowBlockBalance(blockedbalance);
        setBalance(newbalance);
        setBlockBalance(blockedbalance);
    }, [newbalance, blockedbalance]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                handlePopoverClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popoverRef]);
    useEffect(() => {
        if (isLoggedIn) {
            console.log("User is logged in.");
            const tutorial = Cookies.get('tutorial');
            console.log("Tutorial cookie:", tutorial);
            if (tutorial !== "2") {

                setTutorialid(13);
            }
        }
    }, [isLoggedIn]);
    useEffect(() => {
        if (!searchterm) {
            if (precount != 0) {
                const accessToken = cookies.serviceToken;
                const config = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                };
                axios.get("https://api.youbetshop.com/api/feed/poseidon-feed?count=" + precount, config)
                    .then(response => {
                        const events = response.data.data.map(event => ({
                            ...event,
                            openDate: new Date(event.openDate)
                        }));
                        events.sort((a, b) => a.openDate - b.openDate);
                        setFData(events);
                        setData(events);
                        setButtonLoadingPre(false)
                        setLoadingpre(false)
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }

        }

    }, [cookies.serviceToken, searchterm, precount, selectedtype]);

    const dateformat = (dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setHours(originalDate.getHours() - 3);
        return new Intl.DateTimeFormat('tr-TR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        }).format(originalDate);
    };

    useEffect(() => {
        if (coupon.length > 0) {
            const parseDatetime = (dtStr) => new Date(dtStr);

            const timeRange = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

            const filtered = fdata.filter((detailedMatch) => {
                const detailedMatchTime = parseDatetime(detailedMatch.openDate).getTime();
                return coupon.every((match) => {
                    const matchTime = parseDatetime(match.datetime).getTime();
                    const startTime = matchTime - timeRange;
                    const endTime = matchTime + timeRange;

                    if (detailedMatch.id === match.matchid) {
                        return true;
                    } else {
                        return !(startTime < detailedMatchTime && detailedMatchTime < endTime) && matchTime !== detailedMatchTime;
                    }
                });
            });
            setFilteredData(filtered);

        } else {
            setFilteredData(fdata);

        }
    }, [coupon, fdata]);

    useEffect(() => {
        if (coupon.length > 0) {
            const parseDatetime = (dtStr) => new Date(dtStr);

            const timeRange = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
            const filteredLive = livedata.filter((detailedMatch) => {
                const detailedMatchTime = parseDatetime(detailedMatch.openDate).getTime();
                return coupon.every((match) => {
                    const matchTime = parseDatetime(match.datetime).getTime();
                    const startTime = matchTime - timeRange;
                    const endTime = matchTime + timeRange;

                    if (detailedMatch.id === match.matchid) {
                        return true;
                    } else {
                        return !(startTime < detailedMatchTime && detailedMatchTime < endTime) && matchTime !== detailedMatchTime;
                    }
                });
            });
            setFilteredDataLive(filteredLive);
        } else {
            setFilteredDataLive(livedata);
        }
    }, [coupon, livedata])


    const addCoup = (matchid, matchname, datetime, marketid, marketname, oddid, oddname, price) => {

        setCoupon(prevCoupon => {
            const existingIndex = prevCoupon.findIndex(coup =>
                coup.matchid === matchid &&
                coup.marketid === marketid &&
                coup.oddid === oddid
            );
            if (existingIndex !== -1) {
                return prevCoupon.filter((_, index) => index !== existingIndex);
            }
            const matchIndex = prevCoupon.findIndex(coup => coup.matchid === matchid);
            if (matchIndex !== -1) {
                const newCoupon = [...prevCoupon];
                newCoupon[matchIndex] = { matchid, matchname, datetime, marketid, marketname, oddid, oddname, price };
                return newCoupon;
            }
            return [...prevCoupon, { matchid, matchname, datetime, marketid, marketname, oddid, oddname, price }];
        });
    };

    // const isButtonActive = (oddid, matchid, marketid) => {
    //     const ss = coupon.find(coup => coup.oddid === oddid && coup.matchid === matchid && coup.marketid === marketid)
    //     if (coupon.find(coup => coup.oddid === oddid && coup.matchid === matchid && coup.marketid === marketid)) {
    //         console.log(ss)
    //         return true
    //     }

    // };
    const isButtonActive = (oddid, matchid, marketid) => {
        return coupon.some(
            (coup) =>
                coup.oddid === oddid &&
                coup.matchid === matchid &&
                coup.marketid === marketid
        );
    };
    const isButtonActive2 = (oddid, matchid, marketid) => {
        return coupon.some(
            (coup) =>
                coup.oddid === oddid &&
                coup.matchid === matchid &&
                coup.marketid === marketid
        );
    };
    useEffect(() => {
        const total = coupon.reduce((acc, item) => acc * item?.price, 1);
        setTotalPrice(total.toFixed(2));
    }, [coupon]);

    const handleTooltipOpen = () => {
        setOpenTool(prev => !prev);
        if (!opentool) {
            setTimeout(handleTooltipClose, 5000);
        }
    };
    const changeType = (val) => {
        setSelectedtype(val)
        if (val == 2) {
            setPrecount(50)
            setLivecount(0)
            setFilteredDataLive([])
        } else if (val == 1) {
            setLivecount(50)
            setPrecount(0)
            setFilteredData([])
        } else if (val == 3) {
            setFilteredDataLive([])
            setFilteredData([])
            setLivecount(10)
            setPrecount(10)
        }

    }
    const changeOddType = (val) => {
        if (val == "LAY") {
            setOddType("LAY")
            setOddType2("availableToLay");
            setCoupon([]);
        } else {
            setOddType("BACK")
            setOddType2("availableToBack");
            setCoupon([]);
        }

    }
    const handleTooltipClose = () => {
        setOpenTool(false);
    };
    const removeMatchFromCoupon = (matchid) => {
        setCoupon((prevCoupon) => prevCoupon.filter((item) => item.matchid !== matchid));
    };
    const sendCoupon = () => {
        setSendLoading(true)
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        const params = { descriptionar: coupon, mode: "Poseidon", type: oddtype, stake: winvalue, changedodd: changedodd };
        axios.post("https://api.youbetshop.com/api/coupon/createcoupon", params, config)
            .then(response => {
                if (response.data.data.success) {
                    setMessage(translatedata[selectedLanguage].notify.couponcreated);
                    setOpen(true);
                    setSendLoading(false)
                    setStat("success");
                    setWinvalue('');
                    handlePopoverClose();
                    setCoupon([]);
                } else {
                    setMessage(response.data.data.message);
                    setOpen(true);
                    setSendLoading(false)
                    setStat("error");
                }
                recheck();
            })
            .catch(error => {
                console.error('Error creating coupon:', error);
                setMessage(error.response.data.message);
                setOpen(true);
                setSendLoading(false)
                setStat("error");
            });
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('tr-TR', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
    };

    useEffect(() => {
        if (winvalue > 0) {
            setBalance(formatNumber((nowbalance - (winvalue * (totalPrice - 1))).toFixed(2)));
            setBlockBalance(formatNumber((nowblockbalance + (winvalue * (totalPrice - 1))).toFixed(2)));
        } else {
            setBalance(formatNumber(nowbalance));
            setBlockBalance(formatNumber(nowblockbalance));
        }
    }, [winvalue, nowbalance, nowblockbalance, totalPrice]);

    const addLiveCount = () => {
        setButtonLoadingLive(true)
        setLivecount(livecount + 20)
    }
    const addPreCount = () => {
        setButtonLoadingPre(true)
        setPrecount(precount + 20)
    }
    const filterSearch = (e) => {

        if (e.length > 3) {
            setSearchterm(e);
            setSearchValue(e);
        } else {
            setSearchterm();
            setSearchValue(e);
        }
    };

    useEffect(() => {
        if (searchterm && searchterm.length > 3) {
            const fetchData = () => {
                const accessToken = cookies.serviceToken;
                const config = {
                    headers: { Authorization: `Bearer ${accessToken}` },
                };

                axios
                    .get("https://api.youbetshop.com/api/feed/poseidon-feed-search?str=" + searchterm, config)
                    .then(response => {
                        const events = [];
                        const otherEvents = [];

                        response.data.data.forEach(event => {
                            const processedEvent = {
                                ...event,
                                openDate: new Date(event.openDate)
                            };

                            if (!event.state || Object.keys(event.state).length === 0) {
                                events.push(processedEvent);
                            } else {
                                if (event?.state?.elapsedRegularTime <= 85) {
                                    otherEvents.push(processedEvent);
                                }

                            }
                        });
                        events.sort((a, b) => a.openDate - b.openDate);
                        setFData(events);
                        setData(events);
                        setLiveData(otherEvents);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            };

            fetchData(); // İlk istek hemen gönderilir

            // 5 saniyede bir aynı terimle istek atmak için interval başlat
            const intervalId = setInterval(fetchData, 5000);

            // Component unmount olduğunda veya searchterm değiştiğinde interval'i temizle
            return () => clearInterval(intervalId);
        }
    }, [searchterm]);


    const [openRows, setOpenRows] = useState({});


    const handleToggle = (id) => {
        setOpenRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handlePopoverOpen = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const opentutorial = (i) => {
        setTutorialid(i);
    }
    const openPopover = Boolean(anchorEl);
    const inputRef = useRef(null);

    const handleFocus = () => {
        if (isMobile) {
            const element = inputRef.current;
            if (element) {
                setTimeout(() => {
                    // Elemanın bulunduğu konumu hesapla
                    const rect = element.getBoundingClientRect();
                    const offsetTop = rect.top + window.scrollY;

                    // 10px boşluk ekleyerek manuel kaydırma
                    window.scrollTo({
                        top: offsetTop - 10, // 10px boşluk bırak
                        behavior: 'smooth', // Yumuşak kaydırma
                    });
                }, 300); // Sanal klavye açılmasını bekle
            }

        }

    };

    if (isLoggedIn) {
        return (
            <>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="back" onClick={() => {
                            navigate("/");
                            setBalance(formatNumber(nowbalance));
                            setBlockBalance(formatNumber(nowblockbalance));
                        }}>
                            <ArrowBackIcon />
                            <Typography variant="h6">
                                {translatedata[selectedLanguage].poseidon.home}
                            </Typography>
                        </IconButton>
                        <Typography variant="h6" style={{ flexGrow: 1 }} />
                        <Typography variant="body1" style={{ marginRight: '10px' }}>
                            {translatedata[selectedLanguage].poseidon.comission + ": %" + poseidonfee}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container sx={{ marginBottom: "30px", backgroundColor: "#ece4d4", padding: "20px", borderRadius: "15px" }}>
                    
                    {isMobile ? 
                    <Typography variant={"h4"} sx={{ borderBottom: "solid 3px #333333", textAlign: "center", backgroundColor: "#d79f09", borderRadius: "10px", padding: "5px" }}>
                        {"SportBook"}
                    </Typography>:
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant={"h4"} sx={{ borderBottom: "solid 3px #333333", textAlign: "center",width:"33%", backgroundColor: "#d79f09", borderRadius: "10px", padding: "5px" }}>
                        {"SportBook"}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between",width:"67%" }}>
                    <Box sx={{ display: "flex", width:"50%" }}>
                    <TextField
                                    inputRef={inputRef}
                                    label={translatedata[selectedLanguage].poseidon.matchsearch}
                                    onFocus={handleFocus}
                                    onChange={(e) => filterSearch(e.target.value)}
                                    sx={{ width: '100%',marginLeft:"20px" }}
                                    value={searchval}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {searchval && (
                                                    <IconButton onClick={() => { setSearchterm(), filterSearch("") }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                /></Box>
                        <ToggleButtonGroup
                            color="primary"
                            value={selectedtype}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="1" onClick={(e) => changeType(e.target.value)}>Live</ToggleButton>
                            <ToggleButton value="2" onClick={(e) => changeType(e.target.value)}>Pre-match</ToggleButton>
                            <ToggleButton value="3" onClick={(e) => changeType(e.target.value)}>{translatedata[selectedLanguage].poseidon.All}</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            color="primary"
                            value={oddtype}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="BACK" onClick={(e) => changeOddType(e.target.value)}>BACK</ToggleButton>
                            <ToggleButton value="LAY" onClick={(e) => changeOddType(e.target.value)}>LAY</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    </Box>
                    }
                    {isMobile&&
                    <>
                    <Box
                        my={4}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '15px',
                            marginTop: '10px',
                        }}
                    >


                        <Box
                            textAlign="left"

                        >

                            <Typography sx={{ fontSize: '0.7rem' }}>
                                {translatedata[selectedLanguage].poseidon.poseidonbookdesc}
                            </Typography>

                            <Box
                                ml={2}
                                textAlign="left"
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: "0px" }}
                            >
                                <TextField
                                    inputRef={inputRef}
                                    label={translatedata[selectedLanguage].poseidon.matchsearch}
                                    onFocus={handleFocus}
                                    onChange={(e) => filterSearch(e.target.value)}
                                    sx={{ width: '100%' }}
                                    value={searchval}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {searchval && (
                                                    <IconButton onClick={() => { setSearchterm(), filterSearch("") }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={selectedtype}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="1" onClick={(e) => changeType(e.target.value)}>Live</ToggleButton>
                            <ToggleButton value="2" onClick={(e) => changeType(e.target.value)}>Pre-match</ToggleButton>
                            <ToggleButton value="3" onClick={(e) => changeType(e.target.value)}>Hepsi</ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                            color="primary"
                            value={oddtype}
                            exclusive
                            aria-label="Platform"
                        >
                            <ToggleButton value="BACK" onClick={(e) => changeOddType(e.target.value)}>BACK</ToggleButton>
                            <ToggleButton value="LAY" onClick={(e) => changeOddType(e.target.value)}>LAY</ToggleButton>
                        </ToggleButtonGroup>
                        {/* <ToggleButton value="Pre" onClick={()=>toggleDrawer(true)}>Ligler</ToggleButton> */}

                        {/* {drawerOpen && !isMobile2 && (
    <Paper
        sx={{
            position: 'absolute',
            width: '250px',
            height: '100%',
            backgroundColor: '#fff',
            boxShadow: 3,
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'right 0.3s',
            top: !isMobile?"600px":"680px", 
            right: !isMobile?"20%":"1vh", 
            zIndex: 1300, 
        }}
    >
        <Typography variant="h6" sx={{ padding: '10px' }}>
            Panel İçeriği
        </Typography>
        <Button onClick={() => toggleDrawer(false)}>Close Panel</Button>
    </Paper>
)} */}
                    </Box></>}
                    {filteredDataLive?.length > 0 &&
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start", borderBottom: "solid 3px #bcbcbc", marginBottom: "10px" }}>
                            <Typography variant={"h5"}>{"Live"}</Typography>
                            <img src={LiveGif} style={{ maxWidth: "50px", marginLeft: "5px", maxHeight: "50px" }} />
                        </Box>}
                    <TableContainer component={Paper}>
                        {loadinglive ?
                            <Loading />
                            :
                            <>
                                {isMobile ?
                                    filteredDataLive?.map((item, index) => (
                                        item.state?.elapsedRegularTime <= 85 &&
                                        <Paper key={index}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                                transition: 'height 0.3s ease',
                                                borderTop: "solid 1px #d79f09"
                                            }}>
                                            <Grid container sx={{ padding: "15px" }}>
                                                <Grid item xs={6} md={6} sm={6} lg={6}>
                                                    <Typography>{item.name}</Typography>
                                                </Grid>
                                                <Grid item xs={6} md={6} sm={6} lg={6} sx={{ textAlign: 'right' }}>

                                                    <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                                                        <Typography sx={{ color: item?.state ? "green" : "black" }}>{item?.state ? item.state?.elapsedRegularTime ? item.state?.elapsedRegularTime + "'" : "Live" : dateformat(item.openDate)}</Typography>
                                                        <img src={LiveGif} style={{ maxWidth: "25px", marginLeft: "5px", maxHeight: "25px" }} />
                                                    </Box>
                                                    <Typography>{item?.state?.score?.home?.score + " - " + item?.state?.score?.away?.score}</Typography>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <div style={{ overflowX: "auto" }}>
                                                            <Grid container sx={{ whiteSpace: "nowrap" }}>
                                                                <Grid item sx={{ width: "40%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.matchresult} </Typography>
                                                                </Grid>
                                                                {/* <Grid item sx={{ width: "30%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.doublechange}</Typography>
                                                                </Grid>
                                                                <Grid item sx={{ width: "20%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.underover}</Typography>
                                                                </Grid> */}
                                                                <Grid item sx={{ width: "45%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market["Üst/Alt 2.5"]}</Typography>
                                                                </Grid>
                                                                <Grid item sx={{ width: "5%" }}>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid container sx={{ whiteSpace: "nowrap" }}>
                                                                <Grid item sx={{ width: "48%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                            {market.status == "OPEN" ?
                                                                                <>
                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                        </Button>}
                                                                                    {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                        </Button>}
                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                        </Button>}
                                                                                </>
                                                                                :
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                {/* <Grid item sx={{ width: "30%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Çifte Şans" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                            {market.status == "OPEN" ?
                                                                                <>
                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["1 veya X"]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                        </Button>}
                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["X veya 2"]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                        </Button>}
                                                                                    {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["1 veya 2"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                        </Button>}
                                                                                </>
                                                                                :
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item sx={{ width: "20%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                            {market.status == "OPEN" ?
                                                                                <>
                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["Alt 2.5"]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                        </Button>}
                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd["Üst 2.5"]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                        </Button>}
                                                                                </>
                                                                                :
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    ))}
                                                                </Grid> */}
                                                                <Grid item sx={{ width: "40%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                            {market.status == "OPEN" ?
                                                                                <>
                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                        </Button>}
                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                        </Button>}
                                                                                </>
                                                                                :
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item sx={{ width: "5%" }}>             <Button
                                                                    variant="contained"
                                                                    sx={{ padding: '6px', minWidth: '34px', color: '#fff' }}
                                                                    onClick={() => handleToggle(item.id)} // Tıklanınca satırı aç/kapat
                                                                >
                                                                    {openRows[item.id] ? '-' : '+'}
                                                                </Button>   </Grid>

                                                                <TableRow>
                                                                    <TableCell sx={{ padding: 0 }}>
                                                                        <Collapse in={openRows[item.id]} timeout="auto" unmountOnExit>
                                                                            <Box sx={{ padding: 2, backgroundColor: '#f4f4f4' }}>
                                                                                <Grid container spacing={2}>

                                                                                    {item.marketCatalogues.map((market) => {
                                                                                        if (
                                                                                            market.marketName.includes("+") ||
                                                                                            market.marketName.includes("-") ||
                                                                                            (
                                                                                                market.marketName === "Üst/Alt 2.5" ||

                                                                                                market.marketName === "Maç Sonucu")
                                                                                        ) {
                                                                                            return null;
                                                                                        }
                                                                                        return (<Grid item xs={6} md={4} sm={3} lg={2}>
                                                                                            <Typography sx={{ textAlign: "center", fontSize: "0.85rem" }}> {translatedata[selectedLanguage].market[market.marketName]}</Typography>
                                                                                            <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                                { market.status == "OPEN" ? market.runners.length <= 2 ? (
                                                                                                    <>
                                                                                                        {market.runners[0]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                            <Button
                                                                                                                variant="outlined"
                                                                                                                style={{
                                                                                                                    padding: 0,
                                                                                                                    minWidth: "43px",fontSize:"0.65rem",
                                                                                                                    backgroundColor: isButtonActive(
                                                                                                                        market.runners[0].selectionId,
                                                                                                                        item.id,
                                                                                                                        market.marketId
                                                                                                                    )
                                                                                                                        ? "#d79f09"
                                                                                                                        : oddtype === "LAY"
                                                                                                                            ? "#fac9d1"
                                                                                                                            : "#a6d8ff",
                                                                                                                    color: "#333",
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addCoup(
                                                                                                                        item.id,
                                                                                                                        item.name,
                                                                                                                        item.openDate,
                                                                                                                        market.marketId,
                                                                                                                        market.marketName,
                                                                                                                        market.runners[0].selectionId,
                                                                                                                        market.runners[0].runnerName,
                                                                                                                        market.runners[0]?.ex[oddtype2][0]?.price
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}
                                                                                                                <br />
                                                                                                                {market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                                        )}

                                                                                                        {market.runners[1]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                            <Button
                                                                                                                variant="outlined"
                                                                                                                style={{
                                                                                                                    padding: 0,
                                                                                                                    minWidth: "43px",fontSize:"0.65rem",
                                                                                                                    backgroundColor: isButtonActive(
                                                                                                                        market.runners[1].selectionId,
                                                                                                                        item.id,
                                                                                                                        market.marketId
                                                                                                                    )
                                                                                                                        ? "#d79f09"
                                                                                                                        : oddtype === "LAY"
                                                                                                                            ? "#fac9d1"
                                                                                                                            : "#a6d8ff",
                                                                                                                    color: "#333",
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addCoup(
                                                                                                                        item.id,
                                                                                                                        item.name,
                                                                                                                        item.openDate,
                                                                                                                        market.marketId,
                                                                                                                        market.marketName,
                                                                                                                        market.runners[1].selectionId,
                                                                                                                        market.runners[1].runnerName,
                                                                                                                        market.runners[1]?.ex[oddtype2][0]?.price
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}
                                                                                                                <br />
                                                                                                                {market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : <>                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                    {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[2].runnerName]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                </Box></>:
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>}
                                                                                            </Box>
                                                                                        </Grid>)
                                                                                    })}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Paper>
                                    ))
                                    :
                                    filteredDataLive?.length > 0 &&
                                    <Table >
                                        <TableHead sx={{ backgroundColor: "#d5d5d5" }} >
                                            <TableRow  >
                                                <TableCell>{translatedata[selectedLanguage].poseidon.matchname}</TableCell>
                                                <TableCell>{translatedata[selectedLanguage].poseidon.matchdate}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.matchresult}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 2.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 1.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 3.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredDataLive?.map((item, index) => (

                                                item.state?.elapsedRegularTime <= 85 &&
                                                <>
                                                    <TableRow key={item.id}
                                                        sx={{
                                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                                            transition: 'height 0.3s ease',
                                                        }}
                                                    >
                                                        <TableCell><Box sx={{ display: "flex", alignItems: "center", width: "100%" }}><img src={LiveGif} style={{ maxWidth: "25px", marginLeft: "5px", maxHeight: "25px" }} />{item.name}</Box></TableCell>
                                                        <TableCell>
                                                            <Typography sx={{ color: item?.state ? "green" : "black" }}>{item?.state ? item.state?.elapsedRegularTime ? item.state?.elapsedRegularTime + "'" : "Live" : dateformat(item.openDate)}</Typography>


                                                            <Typography>{item?.state?.score?.home?.score + " - " + item?.state?.score?.away?.score}</Typography></TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (

                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.status == "OPEN" ?
                                                                        <>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </>
                                                                        :
                                                                        <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                            <Typography>Askıda</Typography>
                                                                        </Box>}
                                                                </Box>



                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (

                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.status == "OPEN" ?
                                                                        <>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </>
                                                                        :
                                                                        <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                            <Typography>Askıda</Typography>
                                                                        </Box>}
                                                                </Box>

                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 1.5" && (

                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.status == "OPEN" ?
                                                                        <>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </>
                                                                        :
                                                                        <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                            <Typography>Askıda</Typography>
                                                                        </Box>}
                                                                </Box>

                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 3.5" && (

                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.status == "OPEN" ?
                                                                        <>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </>
                                                                        :
                                                                        <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                            <Typography>Askıda</Typography>
                                                                        </Box>}
                                                                </Box>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>               <Button
                                                            variant="contained"
                                                            sx={{ padding: '6px', minWidth: '34px', color: '#fff' }}
                                                            onClick={() => handleToggle(item.id)} // Tıklanınca satırı aç/kapat
                                                        >
                                                            {openRows[item.id] ? '-' : '+'}
                                                        </Button>   </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={7} sx={{ padding: 0 }}>
                                                            <Collapse in={openRows[item.id]} timeout="auto" unmountOnExit>
                                                                <Box sx={{ padding: 2, backgroundColor: '#f4f4f4' }}>
                                                                    <Grid container spacing={2}>

                                                                        {item.marketCatalogues.map((market) => {
                                                                            if (
                                                                                market.marketName.includes("+") ||
                                                                                market.marketName.includes("-") ||
                                                                                (market.marketName === "Üst/Alt 2.5" ||
                                                                                    market.marketName === "Üst/Alt 1.5" ||
                                                                                    market.marketName === "Üst/Alt 3.5" ||
                                                                                    market.marketName === "Maç Sonucu")
                                                                            ) {
                                                                                return null;
                                                                            }
                                                                            return (<Grid item xs={6} md={4} sm={3} lg={2}>
                                                                                <Typography sx={{ textAlign: "center", fontSize: "0.85rem" }}> {translatedata[selectedLanguage].market[market.marketName]}</Typography>
                                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                    {market.status == "OPEN" ? market.runners.length <= 2  ? (
                                                                                        <>
                                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    style={{
                                                                                                        padding: 0,
                                                                                                        minWidth: "43px",fontSize:"0.65rem",
                                                                                                        backgroundColor: isButtonActive(
                                                                                                            market.runners[0].selectionId,
                                                                                                            item.id,
                                                                                                            market.marketId
                                                                                                        )
                                                                                                            ? "#d79f09"
                                                                                                            : oddtype === "LAY"
                                                                                                                ? "#fac9d1"
                                                                                                                : "#a6d8ff",
                                                                                                        color: "#333",
                                                                                                    }}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        addCoup(
                                                                                                            item.id,
                                                                                                            item.name,
                                                                                                            item.openDate,
                                                                                                            market.marketId,
                                                                                                            market.marketName,
                                                                                                            market.runners[0].selectionId,
                                                                                                            market.runners[0].runnerName,
                                                                                                            market.runners[0]?.ex[oddtype2][0]?.price
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}
                                                                                                    <br />
                                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                </Button>
                                                                                            ) : (
                                                                                                <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                            )}

                                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    style={{
                                                                                                        padding: 0,
                                                                                                        minWidth: "43px",fontSize:"0.65rem",
                                                                                                        backgroundColor: isButtonActive(
                                                                                                            market.runners[1].selectionId,
                                                                                                            item.id,
                                                                                                            market.marketId
                                                                                                        )
                                                                                                            ? "#d79f09"
                                                                                                            : oddtype === "LAY"
                                                                                                                ? "#fac9d1"
                                                                                                                : "#a6d8ff",
                                                                                                        color: "#333",
                                                                                                    }}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        addCoup(
                                                                                                            item.id,
                                                                                                            item.name,
                                                                                                            item.openDate,
                                                                                                            market.marketId,
                                                                                                            market.marketName,
                                                                                                            market.runners[1].selectionId,
                                                                                                            market.runners[1].runnerName,
                                                                                                            market.runners[1]?.ex[oddtype2][0]?.price
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}
                                                                                                    <br />
                                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                </Button>
                                                                                            ) : (
                                                                                                <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                            )}
                                                                                        </>
                                                                                    ) : <>                                                               <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                        {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                        {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[2].runnerName]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                        {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                    </Box></>:
                                                                        <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                            <Typography>Askıda</Typography>
                                                                        </Box>}
                                                                                </Box>
                                                                            </Grid>)
                                                                        })}
                                                                    </Grid>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </>))}
                                        </TableBody>
                                    </Table>}
                                {filteredDataLive?.length > 0 &&
                                    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", padding: "10px" }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => addLiveCount()}
                                            disabled={buttonloadinglive} // Optionally disable the button while loading
                                        >
                                            {buttonloadinglive ? <CircularProgress size={24} /> : translatedata[selectedLanguage].poseidon.othermatch}
                                        </Button>
                                    </Box>}
                            </>
                        }
                    </TableContainer>
                    {filteredData?.length > 0 &&
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start", borderBottom: "solid 3px #bcbcbc", marginBottom: "10px", marginTop: "30px" }}>
                            <Typography variant={"h5"}>{"Pre-Match"}</Typography>
                        </Box>}
                    <TableContainer component={Paper}>
                        {loadingpre ?
                            <Loading />
                            :
                            <>
                                  {isMobile ?
                                    filteredData?.map((item, index) => (
                                        <Paper key={index}
                                            sx={{
                                                backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                                transition: 'height 0.3s ease',
                                                borderTop: "solid 1px #d79f09"
                                            }}>
                                            <Grid container sx={{ padding: "15px" }}>
                                                <Grid item xs={6} md={6} sm={6} lg={6}>
                                                    <Typography>{item.name}</Typography>
                                                </Grid>
                                                <Grid item xs={6} md={6} sm={6} lg={6} sx={{ textAlign: 'right' }}>
                                                    <Typography>{dateformat(item.openDate)}</Typography>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <div style={{ overflowX: "auto" }}>
                                                            <Grid container sx={{ whiteSpace: "nowrap" }}>
                                                                <Grid item sx={{ width: "40%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.matchresult} </Typography>
                                                                </Grid>
                                                                {/* <Grid item sx={{ width: "30%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.doublechange}</Typography>
                                                                </Grid>
                                                                <Grid item sx={{ width: "20%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market.underover}</Typography>
                                                                </Grid> */}
                                                                <Grid item sx={{ width: "45%" }}>
                                                                    <Typography sx={{ textAlign: 'center', fontSize: "0.75rem" }}>{translatedata[selectedLanguage].market["Üst/Alt 2.5"]}</Typography>
                                                                </Grid>
                                                                <Grid item sx={{ width: "5%" }}>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid container sx={{ whiteSpace: "nowrap" }}>
                                                                <Grid item sx={{ width: "48%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem", backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                {/* <Grid item sx={{ width: "30%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Çifte Şans" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "110px" }}>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["1 veya X"]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["X veya 2"]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                </Button>}
                                                                            {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["1 veya 2"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                </Button>}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item sx={{ width: "20%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Alt 2.5"]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} sx={{ padding: "0px", minWidth: "43px", fontSize: "0.65rem" ,backgroundColor:isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ?"#d79f09":oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd["Üst 2.5"]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px"  ,backgroundColor:oddtype=="LAY"?"#fac9d1":"#a6d8ff",color:"#333"}} >
                                                                                </Button>}
                                                                        </Box>
                                                                    ))}
                                                                </Grid> */}
                                                                <Grid item sx={{ width: "40%" }}>
                                                                    {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                                        <Box key={market.marketId} sx={{ textAlign: "center", display: "inline-block", width: "100px" }}>
                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                </Button>}
                                                                        </Box>
                                                                    ))}
                                                                </Grid>
                                                                <Grid item sx={{ width: "5%" }}>             <Button
                                                                    variant="contained"
                                                                    sx={{ padding: '6px', minWidth: '34px', color: '#fff' }}
                                                                    onClick={() => handleToggle(item.id)} // Tıklanınca satırı aç/kapat
                                                                >
                                                                    {openRows[item.id] ? '-' : '+'}
                                                                </Button>   </Grid>

                                                                <TableRow>
                                                                    <TableCell sx={{ padding: 0 }}>
                                                                        <Collapse in={openRows[item.id]} timeout="auto" unmountOnExit>
                                                                            <Box sx={{ padding: 2, backgroundColor: '#f4f4f4' }}>
                                                                                <Grid container spacing={2}>

                                                                                    {item.marketCatalogues.map((market) => {
                                                                                        if (
                                                                                            market.marketName.includes("+") ||
                                                                                            market.marketName.includes("-") ||
                                                                                            (
                                                                                                market.marketName === "Üst/Alt 2.5" ||

                                                                                                market.marketName === "Maç Sonucu")
                                                                                        ) {
                                                                                            return null;
                                                                                        }
                                                                                        return (<Grid item xs={6} md={4} sm={3} lg={2}>
                                                                                            <Typography sx={{ textAlign: "center", fontSize: "0.85rem" }}> {translatedata[selectedLanguage].market[market.marketName]}</Typography>
                                                                                            <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                                {market.status == "OPEN" ? market.runners.length <= 2 ? (
                                                                                                    <>
                                                                                                        {market.runners[0]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                            <Button
                                                                                                                variant="outlined"
                                                                                                                style={{
                                                                                                                    padding: 0,
                                                                                                                    minWidth: "43px",fontSize:"0.65rem",
                                                                                                                    backgroundColor: isButtonActive(
                                                                                                                        market.runners[0].selectionId,
                                                                                                                        item.id,
                                                                                                                        market.marketId
                                                                                                                    )
                                                                                                                        ? "#d79f09"
                                                                                                                        : oddtype === "LAY"
                                                                                                                            ? "#fac9d1"
                                                                                                                            : "#a6d8ff",
                                                                                                                    color: "#333",
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addCoup(
                                                                                                                        item.id,
                                                                                                                        item.name,
                                                                                                                        item.openDate,
                                                                                                                        market.marketId,
                                                                                                                        market.marketName,
                                                                                                                        market.runners[0].selectionId,
                                                                                                                        market.runners[0].runnerName,
                                                                                                                        market.runners[0]?.ex[oddtype2][0]?.price
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}
                                                                                                                <br />
                                                                                                                {market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                                        )}

                                                                                                        {market.runners[1]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                            <Button
                                                                                                                variant="outlined"
                                                                                                                style={{
                                                                                                                    padding: 0,
                                                                                                                    minWidth: "43px",fontSize:"0.65rem",
                                                                                                                    backgroundColor: isButtonActive(
                                                                                                                        market.runners[1].selectionId,
                                                                                                                        item.id,
                                                                                                                        market.marketId
                                                                                                                    )
                                                                                                                        ? "#d79f09"
                                                                                                                        : oddtype === "LAY"
                                                                                                                            ? "#fac9d1"
                                                                                                                            : "#a6d8ff",
                                                                                                                    color: "#333",
                                                                                                                }}
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    addCoup(
                                                                                                                        item.id,
                                                                                                                        item.name,
                                                                                                                        item.openDate,
                                                                                                                        market.marketId,
                                                                                                                        market.marketName,
                                                                                                                        market.runners[1].selectionId,
                                                                                                                        market.runners[1].runnerName,
                                                                                                                        market.runners[1]?.ex[oddtype2][0]?.price
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}
                                                                                                                <br />
                                                                                                                {market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                            </Button>
                                                                                                        ) : (
                                                                                                            <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : <>                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                    {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[2].runnerName]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                                        </Button>}
                                                                                                </Box></>:
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>}
                                                                                            </Box>
                                                                                        </Grid>)
                                                                                    })}
                                                                                </Grid>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Paper>
                                    ))
                                    :
                                    filteredData?.length > 0 &&
                                    <Table >
                                        <TableHead sx={{ backgroundColor: "#d5d5d5" }} >
                                            <TableRow  >
                                                <TableCell>{translatedata[selectedLanguage].poseidon.matchname}</TableCell>
                                                <TableCell>{translatedata[selectedLanguage].poseidon.matchdate}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market.matchresult}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 2.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 1.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{translatedata[selectedLanguage].market["Üst/Alt 3.5"]}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData?.map((item, index) => (
                                                <>
                                                    <TableRow key={item.id}
                                                        sx={{
                                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ece4d4',
                                                            transition: 'height 0.3s ease',
                                                        }}
                                                    >
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{dateformat(item.openDate)}</TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Maç Sonucu" && (
                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd["Ev Sahibi "]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                    {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd["Beraberlik"]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd["Deplasman "]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                </Box>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 2.5" && (
                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                </Box>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 1.5" && (
                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                </Box>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px" }}>
                                                            {item.marketCatalogues.map((market) => market.marketName === "Üst/Alt 3.5" && (
                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                    {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                    {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                        <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                            {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                        </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                        </Button>}
                                                                </Box>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell>               <Button
                                                            variant="contained"
                                                            sx={{ padding: '6px', minWidth: '34px', color: '#fff' }}
                                                            onClick={() => handleToggle(item.id)} // Tıklanınca satırı aç/kapat
                                                        >
                                                            {openRows[item.id] ? '-' : '+'}
                                                        </Button>   </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={7} sx={{ padding: 0 }}>
                                                            <Collapse in={openRows[item.id]} timeout="auto" unmountOnExit>
                                                                <Box sx={{ padding: 2, backgroundColor: '#f4f4f4' }}>
                                                                    <Grid container spacing={2}>

                                                                        {item.marketCatalogues.map((market) => {
                                                                            if (
                                                                                market.marketName.includes("+") ||
                                                                                market.marketName.includes("-") ||
                                                                                (market.marketName === "Üst/Alt 2.5" ||
                                                                                    market.marketName === "Üst/Alt 3.5" ||
                                                                                    market.marketName === "Üst/Alt 1.5" ||
                                                                                    market.marketName === "Maç Sonucu")
                                                                            ) {
                                                                                return null;
                                                                            }
                                                                            return (<Grid item xs={6} md={4} sm={3} lg={2}>
                                                                                <Typography sx={{ textAlign: "center", fontSize: "0.85rem" }}> {translatedata[selectedLanguage].market[market.marketName]}</Typography>
                                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                    { market.status == "OPEN" ? market.runners.length <= 2 ? (
                                                                                        <>
                                                                                            {market.runners[0]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    style={{
                                                                                                        padding: 0,
                                                                                                        minWidth: "43px",fontSize:"0.65rem",
                                                                                                        backgroundColor: isButtonActive(
                                                                                                            market.runners[0].selectionId,
                                                                                                            item.id,
                                                                                                            market.marketId
                                                                                                        )
                                                                                                            ? "#d79f09"
                                                                                                            : oddtype === "LAY"
                                                                                                                ? "#fac9d1"
                                                                                                                : "#a6d8ff",
                                                                                                        color: "#333",
                                                                                                    }}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        addCoup(
                                                                                                            item.id,
                                                                                                            item.name,
                                                                                                            item.openDate,
                                                                                                            market.marketId,
                                                                                                            market.marketName,
                                                                                                            market.runners[0].selectionId,
                                                                                                            market.runners[0].runnerName,
                                                                                                            market.runners[0]?.ex[oddtype2][0]?.price
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}
                                                                                                    <br />
                                                                                                    {market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                                </Button>
                                                                                            ) : (
                                                                                                <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                            )}

                                                                                            {market.runners[1]?.ex[oddtype2][0]?.price > 1 ? (
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    style={{
                                                                                                        padding: 0,
                                                                                                        minWidth: "43px",fontSize:"0.65rem",
                                                                                                        backgroundColor: isButtonActive(
                                                                                                            market.runners[1].selectionId,
                                                                                                            item.id,
                                                                                                            market.marketId
                                                                                                        )
                                                                                                            ? "#d79f09"
                                                                                                            : oddtype === "LAY"
                                                                                                                ? "#fac9d1"
                                                                                                                : "#a6d8ff",
                                                                                                        color: "#333",
                                                                                                    }}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        addCoup(
                                                                                                            item.id,
                                                                                                            item.name,
                                                                                                            item.openDate,
                                                                                                            market.marketId,
                                                                                                            market.marketName,
                                                                                                            market.runners[1].selectionId,
                                                                                                            market.runners[1].runnerName,
                                                                                                            market.runners[1]?.ex[oddtype2][0]?.price
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}
                                                                                                    <br />
                                                                                                    {market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                                </Button>
                                                                                            ) : (
                                                                                                <Button style={{ padding: 0, minWidth: "43px" }} />
                                                                                            )}
                                                                                        </>
                                                                                    ) : <>                                                                <Box key={market.marketId} sx={{ textAlign: "center" }}>
                                                                                        {market.runners[0]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[0].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[0].selectionId, market.runners[0].runnerName, market.runners[0]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[0].runnerName]}<br />{market.runners[0]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                        {market.runners[2]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[2].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[2].selectionId, market.runners[2].runnerName, market.runners[2]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[2].runnerName]}<br />{market.runners[2]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                        {market.runners[1]?.ex[oddtype2][0]?.price > 1 ?
                                                                                            <Button variant={'outlined'} style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: isButtonActive(market.runners[1].selectionId, item.id, market.marketId) ? "#d79f09" : oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} onClick={(e) => { e.preventDefault(); addCoup(item.id, item.name, item.openDate, market.marketId, market.marketName, market.runners[1].selectionId, market.runners[1].runnerName, market.runners[1]?.ex[oddtype2][0]?.price) }}>
                                                                                                {translatedata[selectedLanguage].odd[market.runners[1].runnerName]}<br />{market.runners[1]?.ex[oddtype2][0]?.price}
                                                                                            </Button> : <Button style={{ padding: "0px", minWidth: "43px",fontSize:"0.65rem",backgroundColor: oddtype == "LAY" ? "#fac9d1" : "#a6d8ff", color: "#333" }} >
                                                                                            </Button>}
                                                                                    </Box></>:
                                                                                <Box sx={{ width: "100%", border: "solid 1px #d79f09", padding: "10px", borderRadius: "5px" }}>
                                                                                    <Typography>Askıda</Typography>
                                                                                </Box>}
                                                                                </Box>
                                                                            </Grid>)
                                                                        })}
                                                                    </Grid>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>}
                                {filteredData?.length > 0 &&
                                    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", padding: "10px" }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => addPreCount()}
                                            disabled={buttonloadingpre}
                                        >
                                            {buttonloadingpre ? <CircularProgress size={24} /> : translatedata[selectedLanguage].poseidon.othermatch}
                                        </Button>
                                    </Box>}
                            </>}
                    </TableContainer>

                    <Box mt={4} textAlign="center">
                        <Box sx={{ width: "100%", backgroundColor: oddtype == "BACK" ? "#a6d8ff" : "#fac9d1", borderRadius: "5px" }}><Typography variant="h6">{oddtype}</Typography></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="h6"> {translatedata[selectedLanguage].poseidon.totalodd}:</Typography>
                                <Typography variant="h4" color="primary">{totalPrice}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="h6">{oddtype == "LAY" ? translatedata[selectedLanguage].poseidon.winamount : "Tutar"}:</Typography>
                                <TextField type="number" value={winvalue} onChange={(e) => setWinvalue(e.target.value)} variant="outlined" />
                                <Typography variant="body2" color="textSecondary" onClick={() => setWinvalue(((balance / (totalPrice - 1)).toFixed(2)))}>{translatedata[selectedLanguage].poseidon.maxbuys}: <span style={{ textDecoration: "underline", color: "#d79f09", cursor: "pointer" }}>{totalPrice > 1 && oddtype == "LAY" ? (Number(nowbalance) / (totalPrice - 1)).toFixed(2) : Number(nowbalance)}</span></Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Typography variant="h6">{oddtype == "LAY" ? "Bloke Tutarı" : "Kazanç"}:</Typography>
                                <Typography variant="h4" color="secondary">{oddtype == "LAY" ? winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0 : winvalue ? (winvalue * (totalPrice)).toFixed(2) : 0}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box textAlign="center" sx={{ marginBottom: "20px" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 0 // removes any space between elements
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={changedodd}
                                        onChange={(e) => changeoddconf(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                            />
                            <Typography sx={{ fontSize: "0.8rem", ml: -2.5 }}>
                                {translatedata[selectedLanguage].poseidon.changeodd}
                            </Typography>
                        </Box>
                        {senloading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Button variant="contained" color="primary" onClick={sendCoupon}>
                                {translatedata[selectedLanguage].poseidon.sendcoupon}
                            </Button>
                        )}

                    </Box>


                </Container>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity={stat}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {message}
                        <Button sx={{ color: "#ece4d4", textDecoration: "underline" }} onClick={() => navigate("/my-bets")}>
                            {translatedata[selectedLanguage].poseidon.mybets}
                        </Button>
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openPopover}
                    onClose={handlePopoverClose}
                    disableScrollLock={true}
                    PaperProps={{
                        sx: {
                            width: { xs: "100%", sm: "25%" },
                            position: 'fixed',
                            bottom: "5%"// Badge'in hemen üstünde olmasını sağlamak için margin ekleyin
                        }
                    }}
                >
                    <Grid container sx={{ padding: "10px" }}>
                        <Box sx={{ width: "100%", backgroundColor: oddtype == "BACK" ? "#a6d8ff" : "#fac9d1" }}><Typography variant="h6">{oddtype}</Typography></Box>
                        {coupon.length > 0 ? (
                            <>
                                {coupon.map((item, index) => (
                                    <Grid container key={index} sx={{ fontSize: "0.8rem", padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
                                        <Grid item xs={5} sm={5}>
                                            {item.matchname}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            {item.marketname}
                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                            {item.oddname}
                                        </Grid>
                                        <Grid item xs={1} sm={1}>
                                            {item.price}
                                        </Grid>
                                        <Grid item xs={1} sm={1}>
                                            <IconButton onClick={() => removeMatchFromCoupon(item.matchid)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                                {/* Ekstra Grid Bileşeni */}
                                <Grid container sx={{ fontSize: "0.8rem", padding: "5px", alignItems: "center" }}>
                                    <Grid item xs={4.5} sm={4.5}>
                                        {oddtype == "LAY" ?
                                            translatedata[selectedLanguage].poseidon.totalblokamount
                                            : "Muhtemel Kazanç :"}

                                    </Grid>
                                    <Grid item xs={3.5} sm={3.5}>
                                        <Typography sx={{ color: "#d79f09", fontWeight: "800" }}>
                                            {oddtype == "LAY" ?
                                                winvalue ? (winvalue * (totalPrice - 1)).toFixed(2) : 0
                                                : winvalue ? (winvalue * (totalPrice)).toFixed(2) : 0}  </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        {translatedata[selectedLanguage].poseidon.totalodd + ":"}
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        <Typography sx={{ color: "#d79f09", fontWeight: "800" }}> {totalPrice}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                                gap: 0 // removes any space between elements
                                            }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={changedodd}
                                                        onChange={(e) => changeoddconf(e.target.checked)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                }
                                            />
                                            <Typography sx={{ fontSize: "0.8rem", ml: -2.5 }}>
                                                {translatedata[selectedLanguage].poseidon.changeodd}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6} sm={6}>

                                        <TextField
                                            type="number"
                                            value={winvalue}
                                            label={oddtype == "LAY" ? translatedata[selectedLanguage].poseidon.winamount : "Tutar"}
                                            sx={{
                                                width: "100%",
                                                marginTop: "5px",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    top: "2px",
                                                },
                                                "& .MuiInputBase-input": {
                                                    padding: "9px",
                                                },
                                            }}
                                            onChange={(e) => setWinvalue(e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>

                                        {senloading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <Button variant="contained" color="primary" sx={{ width: "100%", marginTop: "12px", marginLeft: "5px", padding: "4px 16px" }} onClick={sendCoupon}>{translatedata[selectedLanguage].poseidon.sendcoupon}</Button>
                                        )}

                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid container sx={{ padding: "5px", borderBottom: "solid 1px #d79f09", alignItems: "center" }}>
                                <Typography sx={{ textAlign: "center", color: "#d79f09" }}>
                                    {translatedata[selectedLanguage].poseidon.nothingcoup}
                                </Typography>
                            </Grid>
                        )}

                    </Grid>
                </Dialog>


                <Fab
                    color="primary"
                    aria-label="invoice"
                    onClick={handlePopoverOpen}
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <Badge
                        badgeContent={coupon.length}
                        color="secondary"
                        sx={{
                            '& .MuiBadge-badge': {
                                position: 'absolute',
                                right: -6,
                                top: -6,
                                backgroundColor: '#000',
                                color: '#fff',
                                borderRadius: '50%',
                                width: '20px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '0.75rem',
                            }
                        }}
                    >
                        <InvoiceIcon />
                    </Badge>
                </Fab>




                <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
            </>
        );
    }
};

export default Poseidon;
